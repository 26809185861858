// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Util from "../common/util.bs.js";
import * as Belt_MapString from "@rescript/std/lib/es6/belt_MapString.js";

var au = Belt_MapString.fromArray([
      [
        "region",
        "AU"
      ],
      [
        "companyName",
        "Prezzee Pty Ltd"
      ],
      [
        "registration",
        "ABN 16602963422"
      ],
      [
        "supportEmail",
        "help@prezzee.com"
      ],
      [
        "corporateEmail",
        "corporate@prezzee.com.au"
      ],
      [
        "phoneNumber",
        "+61 2 9093 2777"
      ],
      [
        "phoneNumberWithoutCountryCode",
        "1800 940 868"
      ],
      [
        "address",
        "Level 3, 9 Castlereagh Street, Sydney, NSW 2000"
      ],
      [
        "personalise",
        "personalise"
      ],
      [
        "Personalise",
        "Personalise"
      ],
      [
        "personalised",
        "personalised"
      ],
      [
        "incentivise",
        "incentivise"
      ],
      [
        "organisations",
        "organisations"
      ],
      [
        "damn",
        "damn"
      ],
      [
        "energised",
        "energised"
      ],
      [
        "customised",
        "customised"
      ],
      [
        "catalogue",
        "catalogue"
      ],
      [
        "apologise",
        "apologise"
      ],
      [
        "riskLink",
        "https://help.prezzee.com.au/hc/en-us/requests/new?ticket_form_id=505267"
      ]
    ]);

var us = Belt_MapString.fromArray([
      [
        "region",
        "US"
      ],
      [
        "companyName",
        "Prezzee Inc"
      ],
      [
        "registration",
        ""
      ],
      [
        "supportEmail",
        "help@prezzee.com"
      ],
      [
        "corporateEmail",
        "corporate@prezzee.com"
      ],
      [
        "phoneNumber",
        "+1 (833) 300-0982"
      ],
      [
        "phoneNumberWithoutCountryCode",
        "(833) 300-0982"
      ],
      [
        "address",
        "1411 Broadway, New York, NY 10018"
      ],
      [
        "personalise",
        "personalize"
      ],
      [
        "Personalise",
        "Personalize"
      ],
      [
        "personalised",
        "personalized"
      ],
      [
        "incentivise",
        "incentivize"
      ],
      [
        "organisations",
        "organizations"
      ],
      [
        "damn",
        ""
      ],
      [
        "energised",
        "energized"
      ],
      [
        "customised",
        "customized"
      ],
      [
        "catalogue",
        "catalog"
      ],
      [
        "apologise",
        "apologize"
      ],
      [
        "riskLink",
        "https://prezzeeus.zendesk.com/hc/en-us/requests/new?ticket_form_id=505267"
      ]
    ]);

var uk = Belt_MapString.fromArray([
      [
        "region",
        "UK"
      ],
      [
        "companyName",
        "Prezzee UK"
      ],
      [
        "registration",
        "CRN 12851966"
      ],
      [
        "supportEmail",
        "help@prezzee.com"
      ],
      [
        "corporateEmail",
        "corporate@prezzee.com"
      ],
      [
        "phoneNumber",
        "+44 808 164 9223"
      ],
      [
        "phoneNumberWithoutCountryCode",
        "0808 164 9223"
      ],
      [
        "address",
        "49 Greek Street London, W1D 4EG. UK"
      ],
      [
        "personalise",
        "personalise"
      ],
      [
        "Personalise",
        "Personalise"
      ],
      [
        "personalised",
        "personalised"
      ],
      [
        "incentivise",
        "incentivise"
      ],
      [
        "organisations",
        "organisations"
      ],
      [
        "damn",
        "damn"
      ],
      [
        "energised",
        "energised"
      ],
      [
        "catalogue",
        "catalogue"
      ],
      [
        "apologise",
        "apologise"
      ],
      [
        "riskLink",
        "https://prezzeeuk.zendesk.com/hc/en-us/requests/new?ticket_form_id=505267"
      ]
    ]);

var nz = Belt_MapString.fromArray([
      [
        "region",
        "NZ"
      ],
      [
        "companyName",
        "Prezzee Limited"
      ],
      [
        "registration",
        "NZBN 9429049008357"
      ],
      [
        "supportEmail",
        "help@prezzee.com"
      ],
      [
        "corporateEmail",
        "corporate@prezzee.co.nz"
      ],
      [
        "phoneNumber",
        "+64 800 627 040"
      ],
      [
        "phoneNumberWithoutCountryCode",
        "0800 627 040"
      ],
      [
        "address",
        "Level 3, 9 Castlereagh Street, Sydney, NSW 2000"
      ],
      [
        "personalise",
        "personalise"
      ],
      [
        "Personalise",
        "Personalise"
      ],
      [
        "personalised",
        "personalised"
      ],
      [
        "incentivise",
        "incentivise"
      ],
      [
        "organisations",
        "organisations"
      ],
      [
        "energised",
        "energised"
      ],
      [
        "damn",
        "damn"
      ],
      [
        "customised",
        "customised"
      ],
      [
        "catalogue",
        "catalogue"
      ],
      [
        "apologise",
        "apologise"
      ],
      [
        "riskLink",
        "https://prezzeenz.zendesk.com/hc/en-us/requests/new?ticket_form_id=505267"
      ]
    ]);

function getLocalizedString(str) {
  var match = Util.EnvUtil.currentCountry;
  if (match.TAG !== /* Ok */0) {
    return Belt_MapString.get(au, str);
  }
  switch (match._0) {
    case /* GreatBritain */1 :
        return Belt_MapString.get(uk, str);
    case /* UnitedStates */2 :
        return Belt_MapString.get(us, str);
    case /* NewZealand */3 :
        return Belt_MapString.get(nz, str);
    case /* Australia */0 :
    case /* Canada */4 :
        return Belt_MapString.get(au, str);
    
  }
}

var MapString;

export {
  MapString ,
  au ,
  us ,
  uk ,
  nz ,
  getLocalizedString ,
}
/* au Not a pure module */
